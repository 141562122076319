import styled, { createGlobalStyle, css } from 'styled-components';

import {
  MdHome,
  MdSettings,
  MdViewCarousel,
  MdPanTool,
  MdNoteAdd,
  MdAccountCircle,
  MdAssignment,
  MdRecordVoiceOver,
  MdInput,
  MdContacts,
  MdDns,
  MdVerifiedUser,
  MdGroupWork,
  MdNewReleases,
  MdChat,
  MdPhone,
  MdEventNote,
} from 'react-icons/md';
import fundo from './assets/fundo.png';

export const GlobalStyle = createGlobalStyle`

	* {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

	*,
    button,
    input {
        font-family: "Roboto", sans-serif;
    }

	span > sup {
		color: #ff0033;
	}

    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
		height: calc(100vh - 50px);
    }

	.rdtPicker {
		border: 1px solid darkcyan;
		box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
	}

	.loadingData {
		background: rgba(0, 0, 0, 0.2);
		color: #000;
		font-weight: bold;

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
	}

	:root {
		--primary: #f0b7a4;
		--secondary: #f18c8e;
		--btnValidar: #ff3366;
		--btnValidarHover: #ce0052;
		--btnHelp: #d5d5d5;
		--btnVoltar: #ff6600;
		--btnVoltar-dark: #660000;
		--btnSalvar: #66ff66;
		--btnSalvar-dark: #003300;
		--btnImage: #555;
		--btnImage-dark: #222;

		--white: #fff;
		--grey: #f5f5f5;
		--cinza: #e5e5e5;
		--gray: #a5a5a5;
	}
`;

const cssIcon = css`
	width: 24px;
	height: 24px;
	color: var(--primary);
	margin-right: 10px;
`;

export const IconNewsletter = styled(MdNewReleases)`
	${cssIcon}
`;

export const IconTeam = styled(MdGroupWork)`
	${cssIcon}
`;

export const IconUser = styled(MdVerifiedUser)`
	${cssIcon}
`;

export const IconAbout = styled(MdContacts)`
	${cssIcon}
`;

export const IconCategory = styled(MdDns)`
	${cssIcon}
`;

export const IconMinistry = styled(MdChat)`
	${cssIcon}
`;

export const IconContact = styled(MdPhone)`
	${cssIcon}
`;

export const IconEvent = styled(MdEventNote)`
	${cssIcon}
`;

export const IconLogout = styled(MdInput)`
	${cssIcon};
	transform: rotate(180deg);
`;

export const IconPrincipal = styled(MdHome)`
	${cssIcon}
`;

export const IconConfig = styled(MdSettings)`
	${cssIcon}
`;

export const IconBanner = styled(MdViewCarousel)`
	${cssIcon}
`;

export const IconProduct = styled(MdNoteAdd)`
	${cssIcon}
`;

export const IconCustomer = styled(MdAccountCircle)`
	${cssIcon}
`;

export const IconOrder = styled(MdAssignment)`
	${cssIcon}
`;

export const IconTestimony = styled(MdRecordVoiceOver)`
	${cssIcon}
`;

export const IconPrayer = styled(MdPanTool)`
	${cssIcon}
`;

export const Container = styled.div`
	margin-top: 50px;
	height: calc(100vh - 50px);
	overflow: hidden;
	position: relative;

	background: url(${fundo});
	background-size: cover;
`;

export const Menu = styled.div`
	overflow-y: hidden;
	height: 100%;
	width: 0;
	background: var(--white);
	box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);

	transition: all 0.2s ease-in-out;
	visibility: hidden;
	position: absolute;
	left: 0;
	z-index: 1;

	&.active {
		overflow-y: auto;
		width: 250px;
		visibility: visible;
	}
`;

export const ItemMenu = styled.div`
	border-bottom: 1px solid var(--btnHelp);
	margin: 5px;
	padding: 8px;

	display: flex;
	align-items: center;

	border-radius: 2px;
	cursor: pointer;

	&:hover {
		background: var(--grey);
	}

	span {
		color: darkred;
	}
`;

export const Content = styled.div`
	height: 100%;
	overflow-y: auto;
`;

import api from './api';

export const signIn = async (email, password) => {
  try {
    return await api.post('/login', { email, password });
  } catch (error) {
    return error;
  }
};

export const signOut = async () => {
  try {
    return await api.get('/logout');
  } catch (error) {
    return error;
  }
};

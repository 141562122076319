import React from 'react';
import { useHeader } from '../../contexts/header';

import logo from '../../assets/logotipo.png';
import { Container, Menu, IconMenu } from './styles';

function Header() {
  const { toggleMenu } = useHeader();

  return (
    <Container>
      <Menu>
        <IconMenu onClick={() => toggleMenu()} />
        <img className="logotipo" src={logo} alt="Mulher com Palavra" />
      </Menu>
    </Container>
  );
}

export default Header;

import React, { useState, useEffect } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { useCustomer } from '../../contexts/customer';
import { getCidades, getUF } from '../../services/ibge';

import {
  Container,
  Content,
  FormItem,
  FormContent,
  FormActions,
  FormActionItem,
  Title,
  IconCustomer,
} from './styles';

function CustomerForm() {
  const {
    currentData,
    currentPage,
    loadCurrentData,
    resetData,
    loading,
    newData,
    editData,
  } = useCustomer();

  const params = useParams();
  const history = useHistory();
  const [uf, setUF] = useState();
  const [cities, setCities] = useState();

  const [form, setForm] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
    cpf: '',
    address_city: '',
    address_complement: '',
    address_district: '',
    address_number: '',
    address_postal_code: '',
    address_state: '',
    address_street: '',
  });

  async function loadUF() {
    const ret = await getUF();
    setUF(ret);
  }

  async function loadCities(sigla) {
    const ret = await getCidades(sigla);
    setCities(ret);
  }

  useEffect(() => {
    resetData();
    loadCurrentData(params.id);
    loadUF();
  }, [params.id]);

  useEffect(() => {
    if (currentData) {
      setForm(currentData);
      loadCities(currentData.address_state);
    } else {
      setForm({
        name: '',
        email: '',
        password: '',
        phone: '',
        cpf: '',
        address_city: '',
        address_complement: '',
        address_district: '',
        address_number: '',
        address_postal_code: '',
        address_state: '',
        address_street: '',
      });

      setCities([]);
    }
  }, [currentData]);

  const voltar = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const handleCities = (e) => {
    loadCities(e.target.value);
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSalvar = async (e) => {
    e.preventDefault();

    let retData;

    if (!params.id || params.id === '0') {
      retData = await newData(form);
    } else {
      retData = await editData(params.id, form);
    }

    if (retData) {
      history.push(currentPage);
    }
  };

  if (loading) {
    return <div className="loadingData">Aguarde carregando...</div>;
  }

  return (
    <Container>
      <Content>
        <form onSubmit={handleSalvar}>
          <Title>
            <IconCustomer />
            <span>Cliente</span>
          </Title>

          <FormContent>
            <FormItem>
              <span>
                Nome
                {' '}
                <sup>*</sup>
              </span>
              <input
                value={form.name}
                name="name"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>
                E-mail
                {' '}
                <sup>*</sup>
              </span>
              <input
                value={form.email}
                name="email"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>
                Senha
                {' '}
                <sup>*</sup>
              </span>
              <input
                value={form.password}
                name="password"
                onChange={(e) => handleChange(e)}
                type="password"
              />
            </FormItem>

            <FormItem>
              <span>Telefone</span>
              <input
                value={form.phone}
                name="phone"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>CPF</span>
              <input
                value={form.cpf}
                name="cpf"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>CEP</span>
              <input
                value={form.address_postal_code}
                name="address_postal_code"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>Logradouro</span>
              <input
                value={form.address_street}
                name="address_street"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>Numero</span>
              <input
                value={form.address_number}
                name="address_number"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>Complemento</span>
              <input
                value={form.address_complement}
                name="address_complement"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>Bairro</span>
              <input
                value={form.address_district}
                name="address_district"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>Estado</span>
              <select
                value={form.address_state}
                name="address_state"
                onChange={(e) => {
								  handleChange(e);
								  handleCities(e);
                }}
              >
                <option>Selecione um item</option>

                {uf
									&& uf.length > 0
									&& uf.map((obj) => (
  <option key={obj.id} value={obj.sigla}>
    {obj.sigla}
    {' '}
    -
    {obj.nome}
  </option>
									))}
              </select>
            </FormItem>

            <FormItem>
              <span>Cidade</span>
              <select
                value={form.address_city}
                name="address_city"
                onChange={(e) => handleChange(e)}
              >
                <option>Selecione um item</option>

                {cities
									&& cities.length > 0
									&& cities.map((obj) => (
  <option key={obj.id} value={obj.nome}>
    {obj.nome}
  </option>
									))}
              </select>
            </FormItem>
          </FormContent>

          <FormActions>
            <FormActionItem className="left">
              <button className="btnVoltar" onClick={(e) => voltar(e)}>
                Voltar
              </button>
            </FormActionItem>

            <FormActionItem className="right">
              <button type="submit" className="btnSalvar">
                Salvar
              </button>
            </FormActionItem>
          </FormActions>
        </form>
      </Content>
    </Container>
  );
}

export default CustomerForm;

import React, { useState, useEffect } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { useOrder } from '../../contexts/order';
import { useCustomer } from '../../contexts/customer';
import { useOrderStatus } from '../../contexts/order_status';
import { getCidades, getUF } from '../../services/ibge';

import {
  Container, Content, FormItem, FormContent, FormActions, FormActionItem, Title, IconOrder,
} from './styles';
import Money from '../../components/Money';

function OrderForm() {
  const {
    currentData, currentPage, loadCurrentData, resetData, loading, newData, editData,
  } = useOrder();

  const params = useParams();
  const history = useHistory();
  const [uf, setUF] = useState();
  const [cities, setCities] = useState();

  const { loadData: loadCustomer, data: dataCustomer } = useCustomer();
  const { loadData: loadStatus, data: dataStatus } = useOrderStatus();

  const [form, setForm] = useState({
    _idCustomer: '',
    _idOrderStatus: '',
    frete: 0,
    address_city: '',
    address_complement: '',
    address_district: '',
    address_number: '',
    address_postal_code: '',
    address_state: '',
    address_street: '',
    address_email: '',
  });

  async function loadUF() {
    const ret = await getUF();
    setUF(ret);
  }

  async function loadCities(sigla) {
    const ret = await getCidades(sigla);
    setCities(ret);
  }

  useEffect(() => {
    resetData();
    loadCurrentData(params.id);
    loadUF();

    loadCustomer(1000);
    loadStatus(1000);
  }, [params.id]);

  useEffect(() => {
    if (currentData) {
      setForm({
        ...currentData,
        _idCustomer: currentData._idCustomer?._id,
        _idOrderStatus: currentData._idOrderStatus?._id,
      });

      loadCities(currentData.address_state);
    } else {
      setForm({
        _idCustomer: '',
        _idOrderStatus: '',
        frete: 0,
        address_city: '',
        address_complement: '',
        address_district: '',
        address_number: '',
        address_postal_code: '',
        address_state: '',
        address_street: '',
        address_email: '',
      });

      setCities([]);
    }
  }, [currentData]);

  const voltar = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const handleCities = (e) => {
    loadCities(e.target.value);
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSalvar = async (e) => {
    e.preventDefault();

    if (!params.id || params.id === '0') {
      await newData(form);
    } else {
      await editData(params.id, form);
    }

    history.push(currentPage);
  };

  if (loading) {
    return <div className="loadingData">Aguarde carregando...</div>;
  }

  return (
    <Container>
      <Content>
        <form onSubmit={handleSalvar}>
          <Title>
            <IconOrder />
            <span>Pedido</span>
          </Title>

          <FormContent>
            <FormItem>
              <span>
                Situação
                {' '}
                <sup>*</sup>
              </span>
              <select value={form._idOrderStatus} name="_idOrderStatus" onChange={(e) => handleChange(e)}>
                <option>Selecione um item</option>

                {dataStatus.map((obj) => (
                  <option key={obj._id} value={obj._id}>
                    {obj.title}
                  </option>
                ))}
              </select>
            </FormItem>

            <FormItem>
              <span>
                Cliente
                {' '}
                <sup>*</sup>
              </span>
              <select value={form._idCustomer} name="_idCustomer" onChange={(e) => handleChange(e)}>
                <option>Selecione um item</option>

                {dataCustomer.map((obj) => (
                  <option key={obj._id} value={obj._id}>
                    {obj.cpf}
                    {' '}
                    -
                    {obj.name}
                  </option>
                ))}
              </select>
            </FormItem>

            <FormItem>
              <span>Frete</span>
              <Money name="frete" initialValue={form.frete} onBlur={(e) => handleChange(e)} />
            </FormItem>

            <FormItem>
              <span>E-mail</span>
              <input value={form.address_email} name="address_email" onChange={(e) => handleChange(e)} />
            </FormItem>

            <FormItem>
              <span>CEP</span>
              <input value={form.address_postal_code} name="address_postal_code" onChange={(e) => handleChange(e)} />
            </FormItem>

            <FormItem>
              <span>Logradouro</span>
              <input value={form.address_street} name="address_street" onChange={(e) => handleChange(e)} />
            </FormItem>

            <FormItem>
              <span>Numero</span>
              <input value={form.address_number} name="address_number" onChange={(e) => handleChange(e)} />
            </FormItem>

            <FormItem>
              <span>Complemento</span>
              <input value={form.address_complement} name="address_complement" onChange={(e) => handleChange(e)} />
            </FormItem>

            <FormItem>
              <span>Bairro</span>
              <input value={form.address_district} name="address_district" onChange={(e) => handleChange(e)} />
            </FormItem>

            <FormItem>
              <span>Estado</span>
              <select
                value={form.address_state}
                name="address_state"
                onChange={(e) => {
								  handleChange(e);
								  handleCities(e);
                }}
              >
                <option>Selecione um item</option>

                {uf
									&& uf.length > 0
									&& uf.map((obj) => (
  <option key={obj.id} value={obj.sigla}>
    {obj.sigla}
    {' '}
    -
    {obj.nome}
  </option>
									))}
              </select>
            </FormItem>

            <FormItem>
              <span>Cidade</span>
              <select value={form.address_city} name="address_city" onChange={(e) => handleChange(e)}>
                <option>Selecione um item</option>

                {cities
									&& cities.length > 0
									&& cities.map((obj) => (
  <option key={obj.id} value={obj.nome}>
    {obj.nome}
  </option>
									))}
              </select>
            </FormItem>
          </FormContent>

          <FormActions>
            <FormActionItem className="left">
              <button className="btnVoltar" onClick={(e) => voltar(e)}>
                Voltar
              </button>
            </FormActionItem>

            <FormActionItem className="right">
              <button type="submit" className="btnSalvar">
                Salvar
              </button>
            </FormActionItem>
          </FormActions>
        </form>
      </Content>
    </Container>
  );
}

export default OrderForm;

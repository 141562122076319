import React, {
  createContext, useContext, useState, useEffect,
} from 'react';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { signIn, signOut } from '../services/login';

const LoginContext = createContext();

export function LoginProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(['MCP_LOGIN']);

  useEffect(() => {
    const storagedUser = localStorage.getItem('mcp-user');
    const storagedToken = localStorage.getItem('mcp-token');

    if (storagedUser && storagedToken) {
      setUser(JSON.parse(storagedUser));
    }

    setLoading(false);
  }, []);

  const login = async (email, password) => {
    setLoading(true);
    const { data } = await signIn(email, password);

    if (!data || data.error) {
      toast(data ? data.error : 'Servidor fora do ar', {
        position: toast.POSITION.BOTTOM_RIGHT,
        type: toast.TYPE.ERROR,
      });

      setLoading(false);
      return false;
    }

    const { token } = data;

    const dtCookie = new Date();
    dtCookie.setHours(dtCookie.getHours() + 2);
    setCookie('MCP_LOGIN', token, { expires: dtCookie });

    const base64 = token.split('.')[1];
    const jwtData = JSON.parse(window.atob(base64));

    const user = jwtData.item;
    setUser(user);

    localStorage.setItem('mcp-user', JSON.stringify(user));
    localStorage.setItem('mcp-token', token);
    setLoading(false);
    return true;
  };

  const logout = async () => {
    setLoading(true);
    const { access_token } = user;

    if (access_token) {
      await signOut();
    }

    localStorage.clear();
    removeCookie('MCP_LOGIN');
    setUser(null);
    setLoading(false);
  };

  return (
    <LoginContext.Provider
      value={{
			  signed: !!user && !!cookies.MCP_LOGIN,
			  user,

			  loading,
			  setLoading,

			  login,
			  logout,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
}

export function useLogin() {
  const context = useContext(LoginContext);
  return context;
}

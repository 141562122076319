import React, { useEffect } from 'react';
import {
  Redirect, BrowserRouter, Route, Switch,
  useHistory, useLocation,
} from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import { useHeader } from './contexts/header';
import { useLogin } from './contexts/login';
import { BannerProvider } from './contexts/banner';
import { ProductProvider } from './contexts/product';
import { CustomerProvider } from './contexts/customer';
import { TestimonyProvider } from './contexts/testimony';
import { PrayerProvider } from './contexts/prayer';
import { CategoryProvider } from './contexts/category';
import { MinistryProvider } from './contexts/ministry';
import { NewsletterProvider } from './contexts/newsletter';
import { UserProvider } from './contexts/user';
import { TeamProvider } from './contexts/team';
import { AboutProvider } from './contexts/about';
import { ContactProvider } from './contexts/contact';
import { EventProvider } from './contexts/event';
import { OrderProvider } from './contexts/order';
import { OrderStatusProvider } from './contexts/order_status';
import { OrderProductProvider } from './contexts/order_product';
import { EventGalleryProvider } from './contexts/event_gallery';

import Header from './components/Header';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';

import Product from './pages/Product';
import ProductForm from './pages/Product/form';
import Customer from './pages/Customer';
import CustomerForm from './pages/Customer/form';
import Banner from './pages/Banner';
import BannerForm from './pages/Banner/form';
import Config from './pages/Config';
import ConfigForm from './pages/Config/form';
import Testimony from './pages/Testimony';
import TestimonyForm from './pages/Testimony/form';
import Ministry from './pages/Ministry';
import MinistryForm from './pages/Ministry/form';
import Category from './pages/Category';
import CategoryForm from './pages/Category/form';
import Newsletter from './pages/Newsletter';
import NewsletterForm from './pages/Newsletter/form';
import User from './pages/User';
import UserForm from './pages/User/form';
import Team from './pages/Team';
import TeamForm from './pages/Team/form';
import About from './pages/About';
import AboutForm from './pages/About/form';
import Contact from './pages/Contact';
import ContactForm from './pages/Contact/form';
import Prayer from './pages/Prayer';
import PrayerForm from './pages/Prayer/form';
import Event from './pages/Event';
import EventForm from './pages/Event/form';
import Order from './pages/Order';
import OrderForm from './pages/Order/form';
import OrderProduct from './pages/OrderProduct';
import OrderProductForm from './pages/OrderProduct/form';
import EventGallery from './pages/EventGallery';
import EventGalleryForm from './pages/EventGallery/form';

import {
  Container, Menu, ItemMenu, Content, IconBanner, IconProduct, IconCustomer, IconOrder, IconTestimony, IconPrayer, IconPrincipal, IconConfig, IconLogout, IconCategory, IconNewsletter, IconTeam, IconUser, IconAbout, IconContact, IconEvent, IconMinistry,
} from './styles';
import { ConfigProvider } from './contexts/config';

function PublicRoute() {
  return (
    <>
      <Route path="/" component={Login} exact />
      <Route path="*" render={() => <Redirect to="/" />} />
    </>
  );
}

function PrivateRoute() {
  const { logout } = useLogin();

  const { open, toggleMenu, setOpen } = useHeader();
  const history = useHistory();

  const goTo = (page) => {
    toggleMenu();
    history.push(page);
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      setOpen(true);
    }
  }, [location]);

  return (
    <Container>
      <Menu className={open ? 'active' : ''}>
        <ItemMenu onClick={() => goTo('/')}>
          <IconPrincipal />
          <span>Principal</span>
        </ItemMenu>
        <ItemMenu onClick={() => goTo('/customer')}>
          <IconCustomer />
          <span>Cliente</span>
        </ItemMenu>
        <ItemMenu onClick={() => goTo('/product')}>
          <IconProduct />
          <span>Loja</span>
        </ItemMenu>
        <ItemMenu onClick={() => goTo('/order')}>
          <IconOrder />
          <span>Pedido</span>
        </ItemMenu>
        <ItemMenu onClick={() => goTo('/banner')}>
          <IconBanner />
          <span>Banner</span>
        </ItemMenu>
        <ItemMenu onClick={() => goTo('/testimony')}>
          <IconTestimony />
          <span>Testemunho</span>
        </ItemMenu>
        <ItemMenu onClick={() => goTo('/prayer')}>
          <IconPrayer />
          <span>Pedido de Oração</span>
        </ItemMenu>
        <ItemMenu onClick={() => goTo('/category')}>
          <IconCategory />
          <span>Categoria</span>
        </ItemMenu>
        <ItemMenu onClick={() => goTo('/ministry')}>
          <IconMinistry />
          <span>Palavra</span>
        </ItemMenu>
        <ItemMenu onClick={() => goTo('/team')}>
          <IconTeam />
          <span>Equipe</span>
        </ItemMenu>
        <ItemMenu onClick={() => goTo('/about')}>
          <IconAbout />
          <span>Sobre</span>
        </ItemMenu>
        <ItemMenu onClick={() => goTo('/event')}>
          <IconEvent />
          <span>Eventos</span>
        </ItemMenu>
        <ItemMenu onClick={() => goTo('/newsletter')}>
          <IconNewsletter />
          <span>Newsletter</span>
        </ItemMenu>
        <ItemMenu onClick={() => goTo('/contact')}>
          <IconContact />
          <span>Contato</span>
        </ItemMenu>
        <ItemMenu onClick={() => goTo('/user')}>
          <IconUser />
          <span>Usuário</span>
        </ItemMenu>
        <ItemMenu onClick={() => goTo('/config')}>
          <IconConfig />
          <span>Configurações</span>
        </ItemMenu>

        <ItemMenu onClick={() => logout()}>
          <IconLogout />
          <span>Sair do sistema</span>
        </ItemMenu>
      </Menu>

      <Content
        onClick={() => {
					  setOpen(false);
        }}
      >
        <Route path="/" component={Dashboard} exact />

        <ConfigProvider>
          <Route path="/config" component={Config} exact />
          <Route path="/config/:id" component={ConfigForm} />
        </ConfigProvider>

        <BannerProvider>
          <Route path="/banner" component={Banner} exact />
          <Route path="/banner/:id" component={BannerForm} />
        </BannerProvider>

        <TestimonyProvider>
          <Route path="/testimony" component={Testimony} exact />
          <Route path="/testimony/:id" component={TestimonyForm} />
        </TestimonyProvider>

        <PrayerProvider>
          <Route path="/prayer" component={Prayer} exact />
          <Route path="/prayer/:id" component={PrayerForm} />
        </PrayerProvider>

        <CustomerProvider>
          <Route path="/customer" component={Customer} exact />
          <Route path="/customer/:id" component={CustomerForm} />

          <OrderStatusProvider>
            <OrderProductProvider>
              <OrderProvider>
                <Route path="/order" component={Order} exact />
                <Route path="/order/:id" component={OrderForm} exact />
              </OrderProvider>
            </OrderProductProvider>
          </OrderStatusProvider>
        </CustomerProvider>

        <ProductProvider>
          <Route path="/product" component={Product} exact />
          <Route path="/product/:id" component={ProductForm} />

          <OrderProductProvider>
            <Route path="/order/:order_id/product" component={OrderProduct} exact />
            <Route path="/order/:order_id/product/:id" component={OrderProductForm} />
          </OrderProductProvider>
        </ProductProvider>

        <CategoryProvider>
          <MinistryProvider>
            <Route path="/ministry" component={Ministry} exact />
            <Route path="/ministry/:id" component={MinistryForm} />
          </MinistryProvider>

          <Route path="/category" component={Category} exact />
          <Route path="/category/:id" component={CategoryForm} />
        </CategoryProvider>

        <TeamProvider>
          <Route path="/team" component={Team} exact />
          <Route path="/team/:id" component={TeamForm} />
        </TeamProvider>

        <AboutProvider>
          <Route path="/about" component={About} exact />
          <Route path="/about/:id" component={AboutForm} />
        </AboutProvider>

        <NewsletterProvider>
          <Route path="/newsletter" component={Newsletter} exact />
          <Route path="/newsletter/:id" component={NewsletterForm} />
        </NewsletterProvider>

        <ContactProvider>
          <Route path="/contact" component={Contact} exact />
          <Route path="/contact/:id" component={ContactForm} />
        </ContactProvider>

        <EventProvider>
          <Route path="/event" component={Event} exact />
          <Route path="/event/:id" component={EventForm} exact />
        </EventProvider>

        <EventGalleryProvider>
          <Route path="/event/:event_id/gallery" component={EventGallery} exact />
          <Route path="/event/:event_id/gallery/:id" component={EventGalleryForm} />
        </EventGalleryProvider>

        <UserProvider>
          <Route path="/user" component={User} exact />
          <Route path="/user/:id" component={UserForm} />
        </UserProvider>

        {/* <Route path="*" render={() => <Redirect to="/" />} /> */}
      </Content>
    </Container>
  );
}

function Routes() {
  const { signed, loading } = useLogin();

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <BrowserRouter>
      <CookiesProvider>
        {signed ? <Header /> : null}
        <Switch>{signed ? <PrivateRoute /> : <PublicRoute />}</Switch>
      </CookiesProvider>
    </BrowserRouter>
  );
}

export default Routes;

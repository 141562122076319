import React, { useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import moment from 'moment';

import { Container, IconCancel } from './styles';

function DTPicker({ onClose, initialValue, name }) {
  const renderInput = (props, openCalendar, closeCalendar) => {
    function open(e) {
      e.preventDefault();
      openCalendar();
    }

    function clear(e) {
      e.preventDefault();

      onClose({
        target: {
          name,
          value: '',
        },
      });

      props.onChange({ target: { value: '' } });
      closeCalendar();
    }

    return (
      <Container>
        <button onClick={open}>
          {props.value === '' ? 'Selecione uma data' : props.value}
        </button>

        <input {...props} style={{ display: 'none' }} />

        <IconCancel onClick={clear} />
      </Container>
    );
  };

  const [date, setDate] = useState();

  useEffect(() => {
    if (initialValue) {
      setDate(moment(initialValue)._d);
    }
  }, [initialValue]);

  const onCloseDatePicker = () => {
    onClose({
      target: {
        name,
        value: new Date(date._d),
      },
    });
  };

  return (
    <Datetime
      renderInput={renderInput}
      onClose={onCloseDatePicker}
      value={date}
      onChange={setDate}
    />
  );
}

export default DTPicker;

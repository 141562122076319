import React from 'react';
import { CSVLink } from 'react-csv';

import {
  Container, IconNew, IconDownload, BtnMore,
} from './styles';

function Grid({
  newData, columns, data, renderItem, existsMoreItems, moreItems, downloadFile,
}) {
  return (
    <>
      {newData && <IconNew onClick={() => newData()} />}

      {downloadFile && (
      <CSVLink data={data}>
        <IconDownload />
      </CSVLink>
      )}

      {data.length === 0 && <div>Nenhum registro encontrado</div>}

      {data.length > 0 && (
      <>
        <Container existsMoreItems={existsMoreItems}>
          <table>
            <thead>
              <tr>
                {columns.map((obj, i) => (
                  <th align={obj.align} key={i}>
                    <label>{obj.title}</label>
                  </th>
                ))}
                <th />
              </tr>
            </thead>

            <tbody>{data.map((obj, i) => renderItem(obj, i))}</tbody>
          </table>
        </Container>

        {existsMoreItems && <BtnMore onClick={() => moreItems()}>Exibir mais itens</BtnMore>}
      </>
      )}
    </>
  );
}

export default Grid;

import styled, { css } from 'styled-components';
import { MdCancel } from 'react-icons/md';

export const Container = styled.div`
	display: flex;
	align-items: center;

	button {
		cursor: pointer;
		padding: 5px 15px;
		border: 0;
		border-radius: 4px;
		background-color: darkcyan;
		font: inherit;
		color: #fff;
	}
`;

const cssIcon = css`
	width: 32px;
	height: 32px;
	color: var(--primary);
	border: 1px solid var(--btnHelp);
	padding: 3px;
	border-radius: 50%;
	cursor: pointer;
	transition: all 0.2s;

	&:hover {
		padding: 4px;
		color: var(--white);
		background: var(--gray);
	}
`;

export const IconCancel = styled(MdCancel)`
	${cssIcon};
	margin-left: 5px;
`;

import styled, { css } from 'styled-components';
import { MdDelete, MdRecordVoiceOver } from 'react-icons/md';

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 15px 0;
	width: 100vw;
	height: calc(100vh - 80px);
`;

export const Content = styled.div`
	width: 95%;
	height: 100%;
	padding: 10px;
	background: var(--white);
	box-shadow: 0 0 25px var(--btnHelp);
	border: 1px solid var(--gray);

	@media (min-width: 600px) {
		width: 90%;
	}

	form {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}
`;

export const ListItem = styled.tr`
	border: 1px solid var(--grey);

	background: ${(props) => (props.i % 2 === 0 ? 'var(--grey)' : 'var(--white)')};

	td {
		padding: 5px 0 3px 5px;

		img {
			width: 75px;
			height: 75px;
			object-fit: cover;
			border-radius: 50%;
		}

		&:first-child {
			width: 110px;
			padding: 5px 0 3px 0;
		}

		&:last-child {
			width: 50px;
			padding: 5px 0 3px 0;
		}
	}

	&:hover {
		cursor: pointer;
		background: var(--cinza);
	}
`;

// export const ListItem = styled.div`

// 	div {
// 		display: flex;
// 		width: 100%;
// 		flex-direction: row;
// 		align-items: center;
// 		justify-content: center;

// 		&:last-child {
// 			flex: 1;
// 		}
// 	}

// 	@media (max-width: 300px) {
// 		div {
// 			flex-direction: column;
// 		}
// 	}

// 	label {
// 		flex: 1;
// 		font-weight: bold;
// 		margin-left: 10px;
// 		margin-right: 10px;
// 		width: 100%;
// 	}

// `;

const cssIcon = css`
	width: 32px;
	height: 32px;
	color: var(--primary);
	border: 1px solid var(--btnHelp);
	padding: 3px;
	border-radius: 50%;
	cursor: pointer;
	transition: all 0.2s;

	&:hover {
		padding: 4px;
		color: var(--white);
		background: var(--gray);
	}
`;

export const IconDel = styled(MdDelete)`
	${cssIcon};
`;

export const IconTestimony = styled(MdRecordVoiceOver)`
	width: 24px;
	height: 24px;
	color: var(--primary);
	margin-right: 10px;
	margin-left: 4px;
`;

export const Title = styled.div`
	font-size: 20px;
	font-weight: bold;
	width: 100%;

	color: var(--secondary);
	display: flex;
	align-items: center;

	margin-bottom: 15px;
`;

export const FormContent = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: auto;
`;

export const FormActions = styled.div`
	width: 100%;
	padding: 5px;
	background: var(--grey);
	display: flex;
	justify-content: center;
`;

export const FormActionItem = styled.div`
	flex: 1;
	display: flex;

	button {
		padding: 5px;
		margin: 0 5px;
		width: 100px;
		font-weight: bold;
		border: 0;
		cursor: pointer;
		transition: all 0.2s;
	}

	.btnVoltar {
		background: var(--btnVoltar);
		color: var(--btnVoltar-dark);

		&:hover {
			background: var(--btnVoltar-dark);
			color: var(--white);
		}
	}

	.btnSalvar {
		background: var(--btnSalvar);
		color: var(--btnSalvar-dark);

		&:hover {
			background: var(--btnSalvar-dark);
			color: var(--white);
		}
	}

	&.left {
		justify-content: flex-start;
	}

	&.right {
		justify-content: flex-end;
	}
`;

export const FormItem = styled.div`
	display: flex;
	flex-direction: column;
	margin: 10px;

	span {
		font-weight: bold;
	}

	input {
		padding: 3px;
		border: 1px solid #555;
		border-radius: 4px;
		height: 25px;
	}

	.radio {
		display: flex;
		align-items: center;

		label {
			display: flex;
			align-items: center;
		}

		label:first-child {
			margin-right: 10px;
		}

		input {
			margin-right: 5px;
		}
	}

	&.file {
		input {
			border: 0;
		}

		label {
			padding: 5px;
			margin: 5px 0;
			max-width: 275px;
			font-weight: bold;
			border: 0;
			cursor: pointer;
			transition: all 0.2s;

			background: var(--btnImage);
			color: var(--white);

			span {
				font-weight: normal;
				font-size: 12px;
			}

			&:hover {
				background: var(--btnImage-dark);
				color: var(--white);
			}
		}

		img {
			width: 150px;
			height: 150px;
			object-fit: cover;
			border-radius: 50%;
		}

		input[type="file"] {
			border: 2px solid #333;
			display: none;
		}
	}
`;

import styled from 'styled-components';
import { MdList } from 'react-icons/md';

export const IconMenu = styled(MdList)`
	position: absolute;
	left: 10px;
	width: 32px;
	height: 32px;
	color: var(--secondary);
	cursor: pointer;
	transition: all 0.2s;

	&:hover {
		padding: 1px;
		background: var(--grey);
		border-radius: 50%;
	}
`;

export const Container = styled.div`
	position: relative;
`;

export const Menu = styled.div`
	z-index: 1;
	background: var(--white);
	box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 50px;

	display: flex;
	align-items: center;
	justify-content: center;

	.logotipo {
		height: 75%;
	}
`;

import React, {
  createContext, useContext, useState, useEffect,
} from 'react';
import { toast } from 'react-toastify';

import api from '../services/api';

const BannerContext = createContext();

const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

export function BannerProvider({ children }) {
  const itemsPerPage = 15;
  const currentPage = '/banner';

  const [pg, setPG] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [existsMoreItems, setExistsMoreItems] = useState(false);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState(null);

  const loadMore = () => {
    setPG((pg) => pg + 1);
  };

  const resetData = () => {
    setData([]);
    setPG(0);
    setTotalItems(0);
    setExistsMoreItems(false);
    setCurrentData(null);
  };

  const loadData = async (qtd) => {
    setLoading(true);

    const page = `?pg=${pg}&qtd=${qtd || itemsPerPage}`;

    const item = await api.get(currentPage + page);
    const { items, total } = item.data;

    setData([...data, ...items]);
    setTotalItems(total);
    setLoading(false);
  };

  const delData = async (id) => {
    setLoading(true);

    await api.delete(`${currentPage}/${id}`);
    setData(data.filter((d) => d._id !== id));
    setTotalItems(totalItems - 1);
    setLoading(false);

    toast('Registro removido com sucesso', {
      position: toast.POSITION.BOTTOM_RIGHT,
      type: toast.TYPE.ERROR,
      autoClose: 2000,
    });
  };

  const editData = async (id, obj, image = null) => {
    setLoading(true);
    let item;

    if (image) {
      const formulario = new FormData();

      Object.keys(obj).forEach((item) => {
        formulario.append(item, obj[item]);
      });

      formulario.append('file', image);
      item = await api.put(`${currentPage}/${id}`, formulario);

      // Aguarda um pouco, para que a imagem seja enviada para o outro servidor
      await sleep(3000);
    } else {
      item = await api.put(`${currentPage}/${id}`, obj);
    }

    // setData([...data.map((d) => (d._id === id ? item.data : d))]);
    setLoading(false);

    toast('Registro editado com sucesso', {
      position: toast.POSITION.BOTTOM_RIGHT,
      type: toast.TYPE.INFO,
      autoClose: 2000,
    });

    return item.data;
  };

  const newData = async (obj, image = null) => {
    setLoading(true);
    let item;

    if (image) {
      const formulario = new FormData();

      Object.keys(obj).forEach((item) => {
        formulario.append(item, obj[item]);
      });

      formulario.append('file', image);
      item = await api.post(currentPage, formulario);

      // Aguarda um pouco, para que a imagem seja enviada para o outro servidor
      await sleep(3000);
    } else {
      item = await api.post(currentPage, obj);
    }

    // setData([item.data, ...data]);
    setTotalItems(totalItems + 1);
    setLoading(false);

    toast('Registro incluido com sucesso', {
      position: toast.POSITION.BOTTOM_RIGHT,
      type: toast.TYPE.SUCCESS,
      autoClose: 2000,
    });

    return item.data;
  };

  const loadCurrentData = async (id) => {
    if (!id || id === '0') {
      setCurrentData(null);
      setLoading(false);
      return;
    }

    setLoading(true);

    const { data } = await api.get(`${currentPage}/${id}`);
    setCurrentData(data);
    setLoading(false);
  };

  useEffect(() => {
    if (pg > 0) {
      loadData();
    }
  }, [pg]);

  useEffect(() => {
    if (data.length < totalItems && totalItems > itemsPerPage) {
      setExistsMoreItems(true);
    } else {
      setExistsMoreItems(false);
    }
  }, [data, totalItems]);

  return (
    <BannerContext.Provider
      value={{
			  loading,
			  data,

			  loadMore,
			  resetData,
			  loadData,
			  delData,
			  editData,
			  newData,
			  loadCurrentData,

			  currentData,
			  existsMoreItems,
			  currentPage,
      }}
    >
      {children}
    </BannerContext.Provider>
  );
}

export function useBanner() {
  return useContext(BannerContext);
}

import React, { useState, useEffect } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { useMinistry } from '../../contexts/ministry';
import { useCategory } from '../../contexts/category';
import HtmlEditor from '../../components/HtmlEditor';

import {
  Container,
  Content,
  FormItem,
  FormContent,
  FormActions,
  FormActionItem,
  Title,
  IconMinistry,
} from './styles';

function MinistryForm() {
  const {
    currentData,
    currentPage,
    loadCurrentData,
    loading,
    resetData,
    newData,
    editData,
  } = useMinistry();

  const { loadData: loadCategory, data: dataCategory } = useCategory();

  const params = useParams();
  const history = useHistory();

  const [form, setForm] = useState({
    title: '',
    description: '',
    _idCategory: '',
    video: '',
    active: false,
  });

  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    loadCurrentData(params.id);
    loadCategory(1000);

    return () => {
      resetData();
    };
  }, [params.id]);

  useEffect(() => {
    if (currentData) {
      setForm({ ...currentData, _idCategory: currentData._idCategory?._id });
      setPreviewImage(currentData.url);
    } else {
      setForm({
        title: '',
        description: '',
        _idCategory: '',
        video: '',
        active: false,
      });
      setImage(null);
      setPreviewImage(null);
    }
  }, [currentData]);

  const voltar = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const handleChangeRadio = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value === 'true',
    });
  };

  const handleChange = (e) => {
    const vlr = ['number', 'tel'].includes(e.target.type)
      ? parseInt(e.target.value)
      : e.target.value;

    setForm({
      ...form,
      [e.target.name]: vlr,
    });
  };

  const onFileChange = (e) => {
    setImage(e.target.files[0]);
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleSalvar = async (e) => {
    e.preventDefault();

    if (!params.id || params.id === '0') {
      await newData(form, image);
    } else {
      await editData(params.id, form, image);
    }

    history.push(currentPage);
  };

  if (loading) {
    return <div className="loadingData">Aguarde carregando...</div>;
  }

  return (
    <Container>
      <Content>
        <form onSubmit={handleSalvar}>
          <Title>
            <IconMinistry />
            <span>Palavra</span>
          </Title>

          <FormContent>
            <FormItem className="file">
              <span>Imagem</span>

              {previewImage && (
              <img
                src={previewImage}
                alt="Preview da imagem"
                title="Preview da imagem"
              />
              )}

              <label>
                <input
                  type="file"
                  name="file"
                  onChange={(e) => onFileChange(e)}
                />
                Clique aqui e selecione um arquivo
                {' '}
                <br />
                <span>Dimensões: largura de 500px e altura de 300px</span>
              </label>
            </FormItem>

            <FormItem>
              <span>Ativo</span>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value
                    name="active"
                    checked={form.active === true}
                    onChange={(e) => handleChangeRadio(e)}
                  />
                  Sim
                </label>
                <label>
                  <input
                    type="radio"
                    value={false}
                    name="active"
                    checked={form.active === false}
                    onChange={(e) => handleChangeRadio(e)}
                  />
                  Não
                </label>
              </div>
            </FormItem>

            <FormItem>
              <span>Categoria</span>
              <select
                value={form._idCategory}
                name="_idCategory"
                onChange={(e) => handleChange(e)}
              >
                <option>Selecione um item</option>

                {dataCategory.map((obj) => (
                  <option key={obj._id} value={obj._id}>
                    {obj.title}
                  </option>
                ))}
              </select>
            </FormItem>

            <FormItem>
              <span>Link do Youtube</span>
              <input
                value={form.video}
                name="video"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>Titulo</span>
              <input
                value={form.title}
                name="title"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>Descrição</span>
              <HtmlEditor
                onFill={handleChange}
                vlr={form.description}
                field="description"
              />
            </FormItem>
          </FormContent>

          <FormActions>
            <FormActionItem className="left">
              <button className="btnVoltar" onClick={(e) => voltar(e)}>
                Voltar
              </button>
            </FormActionItem>

            <FormActionItem className="right">
              <button type="submit" className="btnSalvar">
                Salvar
              </button>
            </FormActionItem>
          </FormActions>
        </form>
      </Content>
    </Container>
  );
}

export default MinistryForm;

import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import HtmlEditor from '../../components/HtmlEditor';

import { useEvent } from '../../contexts/event';

import DTPicker from '../../components/DTPicker';
import Money from '../../components/Money';

import {
  Container,
  Content,
  FormItem,
  FormContent,
  FormActions,
  FormActionItem,
  Title,
  IconEvent,
} from './styles';

function EventForm() {
  const {
    currentData,
    currentPage,
    loadCurrentData,
    resetData,
    loading,
    newData,
    editData,
  } = useEvent();

  const params = useParams();
  const history = useHistory();

  const [form, setForm] = useState({
    position: 0,
    title: '',
    description: '',
    active: false,
    value: 0,
    start_date: '',
    end_date: '',
    link: '',
    type: '',
  });

  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    resetData();
    loadCurrentData(params.id);
  }, [params.id]);

  useEffect(() => {
    if (currentData) {
      setForm(currentData);
      setPreviewImage(currentData.url);
    } else {
      setForm({
        position: 0,
        title: '',
        description: '',
        active: false,
        value: 0,
        start_date: '',
        end_date: '',
        link: '',
        type: '',
      });
      setImage(null);
      setPreviewImage(null);
    }
  }, [currentData]);

  const voltar = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const handleChangeRadio = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value === 'true',
    });
  };

  const handleChange = (e) => {
    const vlr = ['number', 'tel'].includes(e.target.type)
      ? parseInt(e.target.value)
      : e.target.value;

    setForm({
      ...form,
      [e.target.name]: vlr,
    });
  };

  const onFileChange = (e) => {
    setImage(e.target.files[0]);
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleSalvar = async (e) => {
    e.preventDefault();

    if (!params.id || params.id === '0') {
      await newData(form, image);
    } else {
      await editData(params.id, form, image);
    }

    history.push(currentPage);
  };

  if (loading) {
    return <div className="loadingData">Aguarde carregando...</div>;
  }

  return (
    <Container>
      <Content>
        <form onSubmit={handleSalvar}>
          <Title>
            <IconEvent />
            <span>Evento</span>
          </Title>

          <FormContent>
            <FormItem className="file">
              <span>
                Imagem
                {' '}
                <sup>*</sup>
              </span>

              {previewImage && (
              <img
                src={previewImage}
                alt="Preview da imagem"
                title="Preview da imagem"
              />
              )}

              <label>
                <input
                  type="file"
                  name="file"
                  onChange={(e) => onFileChange(e)}
                />
                Clique aqui e selecione um arquivo
                {' '}
                <br />
                <span>Dimensões: largura de 500px e altura de 300px</span>
              </label>
            </FormItem>

            <FormItem>
              <span>Ativo</span>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value
                    name="active"
                    checked={form.active === true}
                    onChange={(e) => handleChangeRadio(e)}
                  />
                  Sim
                </label>
                <label>
                  <input
                    type="radio"
                    value={false}
                    name="active"
                    checked={form.active === false}
                    onChange={(e) => handleChangeRadio(e)}
                  />
                  Não
                </label>
              </div>
            </FormItem>

            <FormItem>
              <span>Tipo</span>
              <select
                value={form.type}
                name="type"
                onChange={(e) => handleChange(e)}
              >
                <option>Selecione um item</option>
                <option value="online">On-line</option>
                <option value="presencial">Presencial</option>
              </select>
            </FormItem>

            <FormItem>
              <span>Ordem</span>
              <input
                type="number"
                value={form.position}
                name="position"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>
                Titulo
                {' '}
                <sup>*</sup>
              </span>
              <input
                value={form.title}
                name="title"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>Valor</span>

              <Money
                name="value"
                initialValue={form.value}
                onBlur={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>Data de início</span>

              <DTPicker
                initialValue={form.start_date}
                name="start_date"
                onClose={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>Data de término</span>
              <DTPicker
                initialValue={form.end_date}
                name="end_date"
                onClose={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>Link do Sympla</span>
              <input
                value={form.link}
                name="link"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>
                Descrição
                {' '}
                <sup>*</sup>
              </span>
              <HtmlEditor
                onFill={handleChange}
                vlr={form.description}
                field="description"
              />
            </FormItem>
          </FormContent>

          <FormActions>
            <FormActionItem className="left">
              <button className="btnVoltar" onClick={(e) => voltar(e)}>
                Voltar
              </button>
            </FormActionItem>

            <FormActionItem className="right">
              <button type="submit" className="btnSalvar">
                Salvar
              </button>
            </FormActionItem>
          </FormActions>
        </form>
      </Content>
    </Container>
  );
}

export default EventForm;

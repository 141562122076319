import React, { useState, useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';

function Money({
  onBlur, initialValue, name, readOnly = false,
}) {
  const [vlr, setVlr] = useState(0);

  useEffect(() => {
    if (parseFloat(initialValue) !== '') {
      setVlr(initialValue);
    }
  }, [initialValue]);

  return (
    <CurrencyFormat
      prefix="R$ "
      decimalSeparator=","
      thousandSeparator="."
      displayType={readOnly ? 'text' : 'input'}
      type="tel"
      name={name}
      value={vlr}
      isNumericString
      onBlur={() => {
				  onBlur({
				    target: { name, value: vlr },
				  });
      }}
      onValueChange={(values) => {
				  const { value } = values;
				  setVlr(parseFloat(parseFloat(value).toFixed(2)) || 0);
      }}
    />
  );
}

export default Money;

import React, { useState, useEffect } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useProduct } from '../../contexts/product';
import Money from '../../components/Money';
import HtmlEditor from '../../components/HtmlEditor';

import {
  Container, Content, FormItem, FormContent, FormActions, FormActionItem, Title, IconProduct,
} from './styles';
import { isEmpty } from '../../services/util';

function ProductForm() {
  const {
    currentData, currentPage, loadCurrentData, resetData, loading, newData, editData,
  } = useProduct();

  const params = useParams();
  const history = useHistory();

  const [form, setForm] = useState({
    title: '',
    value: 0,
    active: false,
    is_virtual: false,
    disable_shopping_cart: false,
    description: '',
    width: 0,
    height: 0,
    length: 0,
    weight: 0,
  });

  const [image, setImage] = useState(null);
  const [download, setDownload] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    resetData();
    loadCurrentData(params.id);
  }, [params.id]);

  useEffect(() => {
    if (currentData) {
      setForm(currentData);
      setPreviewImage(currentData.url);
    } else {
      setForm({
        title: '',
        value: 0,
        active: false,
        is_virtual: false,
        disable_shopping_cart: false,
        description: '',
        width: 0,
        height: 0,
        length: 0,
        weight: 0,
      });
      setImage(null);
      setDownload(null);
      setPreviewImage(null);
    }
  }, [currentData]);

  const voltar = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const handleChangeRadio = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value === 'true',
    });
  };

  const handleChange = (e) => {
    const vlr = ['number', 'tel'].includes(e.target.type) ? parseInt(e.target.value) : e.target.value;

    setForm({
      ...form,
      [e.target.name]: vlr,
    });
  };

  const onFileChange = (e) => {
    setImage(e.target.files[0]);
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
  };

  const onFileChangeDownload = (e) => {
    setDownload(e.target.files[0]);
  };

  const handleSalvar = async (e) => {
    e.preventDefault();

    if (form.value > 0) {
      if (form.is_virtual === false) {
        if (form.width === 0 || form.height === 0 || form.length === 0 || form.weight === 0) {
          toast('Se houver um valor informado, o item deve possui: Largura, Altura, Comprimento e Peso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
            type: toast.TYPE.INFO,
            autoClose: 4000,
          });
          return;
        }
      }
    } else if (form.is_virtual === false) {
      toast('Se o valor informado for igual a zero, o item deve ser considerado virtual.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        type: toast.TYPE.INFO,
        autoClose: 4000,
      });
      return;
    }

    if (!params.id || params.id === '0') {
      await newData(form, image, download);
    } else {
      await editData(params.id, form, image, download);
    }

    history.push(currentPage);
  };

  useEffect(() => {
    if (form.is_virtual) {
      setForm({
        ...form,
        width: 0,
        height: 0,
        length: 0,
        weight: 0,
      });
    }
  }, [form.is_virtual]);

  const fileType = (d, f) => {
    const fName = d ? d.name : f.download;
    const ext = fName.substring(fName.lastIndexOf('.') + 1);

    switch (ext) {
      case 'jpeg':
      case 'gif':
      case 'jpg':
      case 'png':
        return 'https://www.mulhercompalavra.com.br/files/image.png';
      case 'pdf':
        return 'https://www.mulhercompalavra.com.br/files/pdf.png';
      case 'doc':
      case 'docx':
        return 'https://www.mulhercompalavra.com.br/files/doc.png';
      case 'zip':
        return 'https://www.mulhercompalavra.com.br/files/zip.jpeg';
      default:
        return 'https://www.mulhercompalavra.com.br/files/none.jpg';
    }
  };

  if (loading) {
    return <div className="loadingData">Aguarde carregando...</div>;
  }

  return (
    <Container>
      <Content>
        <form onSubmit={handleSalvar}>
          <Title>
            <IconProduct />
            <span>Loja</span>
          </Title>

          <FormContent>
            <FormItem className="file">
              <span>
                Imagem
                {' '}
                <sup>*</sup>
              </span>

              {previewImage && <img src={previewImage} alt="Preview da imagem" title="Preview da imagem" />}

              <label>
                <input type="file" name="file" onChange={(e) => onFileChange(e)} />
                Clique aqui e selecione um arquivo
                {' '}
                <br />
                <span>Dimensões: largura de 500px e altura de 300px</span>
              </label>
            </FormItem>

            <FormItem className="file">
              <span>Arquivo</span>

              {(download || form.download) && <img src={fileType(download, form)} alt="Anexo para download" title="Anexo para download" className="iconeDownload" />}

              <label>
                <input type="file" name="file_download" onChange={(e) => onFileChangeDownload(e)} />
                Clique aqui e selecione um arquivo
                {' '}
                <br />
                <span>Tipo: PDF ou imagem</span>
                <br />
                <br />
                <div>
                  <span>Este arquivo e apenas uma previa, caso seja um produto digital, voce deve enviar manualmente ao cliente via email</span>
                </div>
              </label>
            </FormItem>

            <FormItem>
              <span>Ativo</span>
              <div className="radio">
                <label>
                  <input type="radio" value name="active" checked={form.active === true} onChange={(e) => handleChangeRadio(e)} />
                  Sim
                </label>
                <label>
                  <input type="radio" value={false} name="active" checked={form.active === false} onChange={(e) => handleChangeRadio(e)} />
                  Não
                </label>
              </div>
            </FormItem>

            <FormItem>
              <span>Produto apenas virtual</span>
              <div className="radio">
                <label>
                  <input type="radio" value name="is_virtual" checked={form.is_virtual === true} onChange={(e) => handleChangeRadio(e)} />
                  Sim
                </label>
                <label>
                  <input type="radio" value={false} name="is_virtual" checked={form.is_virtual === false} onChange={(e) => handleChangeRadio(e)} />
                  Não
                </label>
              </div>
            </FormItem>

            <FormItem>
              <span>Adicionar ao carrinho</span>
              <div className="radio">
                <label>
                  <input type="radio" value name="disable_shopping_cart" checked={form.disable_shopping_cart === true} onChange={(e) => handleChangeRadio(e)} />
                  Sim
                </label>
                <label>
                  <input type="radio" value={false} name="disable_shopping_cart" checked={form.disable_shopping_cart === false} onChange={(e) => handleChangeRadio(e)} />
                  Não
                </label>
              </div>
            </FormItem>

            <FormItem>
              <span>
                Titulo
                {' '}
                <sup>*</sup>
              </span>
              <input value={form.title} name="title" onChange={(e) => handleChange(e)} />
            </FormItem>

            <FormItem>
              <span>Valor</span>

              <Money name="value" initialValue={form.value} onBlur={(e) => handleChange(e)} />
            </FormItem>

            <FormItem>
              <span>Largura (cm)</span>
              <input value={form.width} name="width" onChange={(e) => handleChange(e)} />
            </FormItem>

            <FormItem>
              <span>Altura (cm)</span>
              <input value={form.height} name="height" onChange={(e) => handleChange(e)} />
            </FormItem>

            <FormItem>
              <span>Comprimento (cm)</span>
              <input value={form.length} name="length" onChange={(e) => handleChange(e)} />
            </FormItem>

            <FormItem>
              <span>Peso (gr)</span>
              <input value={form.weight} name="weight" onChange={(e) => handleChange(e)} />
            </FormItem>

            <FormItem>
              <span>
                Descrição
                {' '}
                <sup>*</sup>
              </span>
              <HtmlEditor onFill={handleChange} vlr={form.description} field="description" />
            </FormItem>
          </FormContent>

          <FormActions>
            <FormActionItem className="left">
              <button className="btnVoltar" onClick={(e) => voltar(e)}>
                Voltar
              </button>
            </FormActionItem>

            <FormActionItem className="right">
              <button type="submit" className="btnSalvar">
                Salvar
              </button>
            </FormActionItem>
          </FormActions>
        </form>
      </Content>
    </Container>
  );
}

export default ProductForm;

import React, { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useHistory } from 'react-router-dom';
import Grid from '../../components/Grid';

import { useProduct } from '../../contexts/product';

import {
  Container, Content, ListItem, IconDel, Title, IconProduct,
} from './styles';
import MoneyFormat from '../../services/money';

function Product() {
  const {
    resetData, data, existsMoreItems, loadMore, loadData, loading, delData, currentPage,
  } = useProduct();

  const history = useHistory();

  useEffect(() => {
    loadData();

    return () => {
      resetData();
    };
  }, []);

  const goTo = (id) => {
    history.push(`${currentPage}/${id}`);
  };

  return (
    <Container>
      <Content>
        <Title>
          <IconProduct />
          <span>Loja</span>
        </Title>

        <Grid
          newData={() => goTo(0)}
          existsMoreItems={existsMoreItems}
          moreItems={() => loadMore()}
          data={data}
          columns={[
					  { title: 'Imagem', align: 'center' },
					  { title: 'Titulo', align: 'left' },
					  { title: 'Valor', align: 'center' },
					  { title: 'Produto apenas virtual', align: 'center' },
					  { title: 'Adicionar ao carrinho', align: 'center' },
					  { title: 'Ativo', align: 'center' },
          ]}
          renderItem={(item, i) => ItemGrid(item, i, delData, goTo)}
        />

        {loading && <div className="loadingData">Aguarde carregando...</div>}
      </Content>
    </Container>
  );
}

function ItemGrid(item, i, delData, goTo) {
  return (
    <ListItem key={item._id} i={i}>
      <td align="center" onClick={() => goTo(item._id)}>
        <LazyLoadImage alt={item.title} effect="blur" src={item.url} />
      </td>

      <td onClick={() => goTo(item._id)}>
        <label>{item.title}</label>
      </td>

      <td align="center" onClick={() => goTo(item._id)}>
        <label>{MoneyFormat(item.value)}</label>
      </td>

      <td align="center" onClick={() => goTo(item._id)}>
        <label>{item.is_virtual ? 'Sim' : 'Não'}</label>
      </td>

      <td align="center" onClick={() => goTo(item._id)}>
        <label>{item.disable_shopping_cart ? 'Sim' : 'Não'}</label>
      </td>

      <td align="center" onClick={() => goTo(item._id)}>
        <label>{item.active ? 'Sim' : 'Não'}</label>
      </td>

      <td align="center">
        <IconDel onClick={() => delData(item._id)} />
      </td>
    </ListItem>
  );
}

export default Product;

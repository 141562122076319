import React, { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useHistory } from 'react-router-dom';
import Grid from '../../components/Grid';

import { useAbout } from '../../contexts/about';

import {
  Container,
  Content,
  ListItem,
  IconDel,
  Title,
  IconAbout,
} from './styles';

function About() {
  const {
    resetData,
    data,
    existsMoreItems,
    loadMore,
    loadData,
    loading,
    delData,
    currentPage,
  } = useAbout();

  const history = useHistory();

  useEffect(() => {
    loadData();

    return () => {
      resetData();
    };
  }, []);

  const goTo = (id) => {
    history.push(`${currentPage}/${id}`);
  };

  return (
    <Container>
      <Content>
        <Title>
          <IconAbout />
          <span>Sobre</span>
        </Title>

        <Grid
          newData={() => goTo(0)}
          existsMoreItems={existsMoreItems}
          moreItems={() => loadMore()}
          data={data}
          columns={[
					  { title: 'Ordem', align: 'center' },
					  { title: 'Imagem', align: 'center' },
					  { title: 'Titulo', align: 'left' },
          ]}
          renderItem={(item, i) => ItemGrid(item, i, delData, goTo)}
        />

        {loading && <div className="loadingData">Aguarde carregando...</div>}
      </Content>
    </Container>
  );
}

function ItemGrid(item, i, delData, goTo) {
  return (
    <ListItem key={item._id} i={i}>
      <td align="center" onClick={() => goTo(item._id)}>
        <label>{item.position}</label>
      </td>

      <td align="center" onClick={() => goTo(item._id)}>
        <LazyLoadImage alt={item.title} effect="blur" src={item.url} />
      </td>

      <td onClick={() => goTo(item._id)}>
        <label>{item.title}</label>
      </td>

      {/* <td onClick={() => goTo(item._id)} className="description">
				<label dangerouslySetInnerHTML={{ __html: item.description }}></label>
			</td> */}

      <td align="center">
        <IconDel onClick={() => delData(item._id)} />
      </td>
    </ListItem>
  );
}

export default About;

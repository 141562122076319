import React, { useEffect } from 'react';
import Routes from './routes';

import 'react-quill/dist/quill.snow.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-datetime/css/react-datetime.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import { LoginProvider } from './contexts/login';
import { HeaderProvider } from './contexts/header';

import { getUF } from './services/ibge';

import { GlobalStyle } from './styles';
import 'moment/locale/pt-br';

function App() {
  useEffect(() => {
    getUF();
  }, []);

  return (
    <LoginProvider>
      <HeaderProvider>
        <Routes />
        <ToastContainer />
        <GlobalStyle />
      </HeaderProvider>
    </LoginProvider>
  );
}

export default App;

import React, { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useHistory, useParams } from 'react-router-dom';
import Grid from '../../components/Grid';

import { useEventGallery } from '../../contexts/event_gallery';

import {
  Container,
  Content,
  ListItem,
  IconDel,
  Title,
  IconBack,
  IconGallery,
} from './styles';

function EventGallery() {
  const {
    resetData,
    data,
    existsMoreItems,
    loadMore,
    loading,
    delData,
    loadData,
    setEventId,
    eventId,
  } = useEventGallery();

  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    setEventId(params.event_id);
  }, [params.event_id]);

  useEffect(() => {
    resetData();
    loadData();

    return () => {
      resetData();
    };
  }, [eventId]);

  const goBack = () => {
    history.push('/event');
  };

  const goTo = (id) => {
    history.push(`/event/${eventId}/gallery/${id}`);
  };

  return (
    <Container>
      <Content>
        <Title>
          <IconBack onClick={() => goBack()} />
          <IconGallery />
          <span>Galeria de Imagens</span>
        </Title>

        <Grid
          newData={() => goTo(0)}
          existsMoreItems={existsMoreItems}
          moreItems={() => loadMore()}
          data={data}
          columns={[{ title: 'Imagem', align: 'center' }]}
          renderItem={(item, i) => ItemGrid(item, i, delData, goTo)}
        />

        {loading && <div className="loadingData">Aguarde carregando...</div>}
      </Content>
    </Container>
  );
}

function ItemGrid(item, i, delData, goTo) {
  return (
    <ListItem key={item._id} i={i}>
      <td align="center" onClick={() => goTo(item._id)}>
        <LazyLoadImage alt={item.title} effect="blur" src={item.url} />
      </td>

      <td align="center">
        <IconDel onClick={() => delData(item._id)} />
      </td>
    </ListItem>
  );
}

export default EventGallery;

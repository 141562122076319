import styled from 'styled-components';
import fundo from '../../assets/fundo.png';

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	min-width: 340px;
	min-height: 410px;
	background: url(${fundo});
`;

export const Content = styled.div`
	padding: 15px;
	box-shadow: 0 0 25px #ccc;
	background-color: var(--white);
`;

export const Logotipo = styled.img`
	margin: auto;
	width: 100px;
	margin-bottom: 20px;
`;

export const Form = styled.form`
	display: flex;
	width: 300px;
	flex-direction: column;

	div {
		display: flex;
		flex-direction: column;
		margin: 10px 0;

		span {
			color: var(--gray);
			text-transform: uppercase;
			font-size: 10px;
			font-weight: bold;
			margin: 5px 0;
		}

		input {
			border: 0;
			border-bottom: 1px solid var(--gray);
		}
	}
`;

export const BoxHelp = styled.div`
	display: flex;
	flex-direction: row;
	padding: 3px;

	button {
		flex: 1;
	}
`;

export const BtnSign = styled.button`
	border-radius: 4px;
	border: 0;
	color: var(--white);
	font-weight: bold;
	padding: 10px;
	margin: 5px 1px;
	transition: all 0.5s;
	background-color: var(--btnValidar);

	&:hover {
		background-color: var(--btnValidarHover);
		cursor: pointer;
	}
`;

import React, { useState, useEffect } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { usePrayer } from '../../contexts/prayer';

import {
  Container,
  Content,
  FormItem,
  FormContent,
  FormActions,
  FormActionItem,
  Title,
  IconPrayer,
} from './styles';

function PrayerForm() {
  const {
    currentData,
    currentPage,
    loadCurrentData,
    resetData,
    loading,
    newData,
    editData,
  } = usePrayer();

  const params = useParams();
  const history = useHistory();

  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  useEffect(() => {
    resetData();
    loadCurrentData(params.id);
  }, [params.id]);

  useEffect(() => {
    if (currentData) {
      setForm(currentData);
    } else {
      setForm({
        name: '',
        email: '',
        phone: '',
        message: '',
      });
    }
  }, [currentData]);

  const voltar = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSalvar = async (e) => {
    e.preventDefault();

    if (!params.id || params.id === '0') {
      await newData(form);
    } else {
      await editData(params.id, form);
    }

    history.push(currentPage);
  };

  if (loading) {
    return <div className="loadingData">Aguarde carregando...</div>;
  }

  return (
    <Container>
      <Content>
        <form onSubmit={handleSalvar}>
          <Title>
            <IconPrayer />
            <span>Pedido de Oração</span>
          </Title>

          <FormContent>
            <FormItem>
              <span>
                Nome
                {' '}
                <sup>*</sup>
              </span>
              <input
                value={form.name}
                name="name"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>E-mail</span>
              <input
                value={form.email}
                name="email"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>Telefone</span>
              <input
                value={form.phone}
                name="phone"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>
                Mensagem
                {' '}
                <sup>*</sup>
              </span>
              <textarea
                rows={10}
                value={form.message}
                name="message"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>
          </FormContent>

          <FormActions>
            <FormActionItem className="left">
              <button className="btnVoltar" onClick={(e) => voltar(e)}>
                Voltar
              </button>
            </FormActionItem>

            <FormActionItem className="right">
              <button type="submit" className="btnSalvar">
                Salvar
              </button>
            </FormActionItem>
          </FormActions>
        </form>
      </Content>
    </Container>
  );
}

export default PrayerForm;

import axios from 'axios';

const api = axios.create({
  // baseURL: process.env.REACT_APP_BACKEND_PORT,
  baseURL: 'https://mcp-backend.vercel.app/api',
});

api.interceptors.request.use(
  (config) => {
    config.headers['x-access-token'] = localStorage.getItem('mcp-token');
    return config;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status !== 304) {
      if ((error.response && error.response.status === 401) || error.toString().indexOf('Error') > -1) {
        localStorage.clear();

        if (window.location.pathname !== '/' && window.location.pathname !== '/login') {
          window.location.href = '/login';
        }
      }

      if (!error.response.data.auth) {
        return error.response;
      }

      return Promise.reject(error);
    }
  },
);

export default api;

import React, { useState, useEffect } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import Money from '../../components/Money';
import { useOrderProduct } from '../../contexts/order_product';
import { useProduct } from '../../contexts/product';

import {
  Container,
  Content,
  FormItem,
  FormContent,
  FormActions,
  FormActionItem,
  Title,
  IconProduct,
} from './styles';

function OrderProductForm() {
  const {
    currentData,
    loadCurrentData,
    loading,
    resetData,
    newData,
    setOrderId,
    orderId,
    editData,
  } = useOrderProduct();

  const params = useParams();
  const history = useHistory();

  const [form, setForm] = useState({
    _idOrder: '',
    _idProduct: '',
    unit: 0,
    qtd: 1,
  });

  const { loadData: loadProduct, data: dataProduct } = useProduct();

  useEffect(() => {
    setOrderId(params.order_id);
    loadProduct(1000);
  }, []);

  useEffect(() => {
    if (orderId) {
      loadCurrentData(params.id);
    }

    return () => {
      resetData();
    };
  }, [orderId, params.id]);

  useEffect(() => {
    if (currentData) {
      setForm({
        ...currentData,
        _idOrder: currentData._idOrder._id,
        _idProduct: currentData._idProduct._id,
      });
    } else {
      setForm({
        _idOrder: '',
        _idProduct: '',
        unit: 0,
        qtd: 1,
      });
    }
  }, [currentData]);

  useEffect(() => {
    if (form._idProduct) {
      setForm({
        ...form,
        total: parseFloat((form.qtd * form.unit).toFixed(2)),
      });
    } else {
      setForm({
        total: 0,
      });
    }
  }, [form.qtd, form.unit]);

  const voltar = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const changeValueProduct = (e) => {
    const prod = dataProduct.find((i) => i._id === e.target.value);

    if (prod) {
      setForm({
        ...form,
        _idProduct: prod._id,
        qtd: 1,
        unit: prod.value,
      });
    } else {
      setForm({
        ...form,
        _idProduct: undefined,
        qtd: 1,
        unit: 0,
      });
    }
  };

  const handleChange = (e) => {
    const vlr = ['number', 'tel'].includes(e.target.type)
      ? parseInt(e.target.value)
      : e.target.value;

    setForm({
      ...form,
      [e.target.name]: vlr,
    });
  };

  const handleSalvar = async (e) => {
    e.preventDefault();

    if (!params.id || params.id === '0') {
      await newData(form);
    } else {
      await editData(params.id, form);
    }

    history.push(`/order/${orderId}/product`);
  };

  if (loading) {
    return <div className="loadingData">Aguarde carregando...</div>;
  }

  return (
    <Container>
      <Content>
        <form onSubmit={handleSalvar}>
          <Title>
            <IconProduct />
            <span>Item do pedido</span>
          </Title>

          <FormContent>
            <FormItem>
              <span>Loja</span>
              <select
                value={form._idProduct}
                name="_idProduct"
                onChange={(e) => {
								  handleChange(e);
								  changeValueProduct(e);
                }}
              >
                <option>Selecione um item</option>

                {dataProduct.map((obj) => (
                  <option key={obj._id} value={obj._id}>
                    {obj.title}
                  </option>
                ))}
              </select>
            </FormItem>

            <FormItem>
              <span>
                Quantidade
                {' '}
                <sup>*</sup>
              </span>
              <input
                type="tel"
                value={form.qtd}
                name="qtd"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>
                Valor (unit)
                {' '}
                <sup>*</sup>
              </span>
              <Money
                name="unit"
                initialValue={form.unit}
                onBlur={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>Total</span>
              <Money readOnly name="total" initialValue={form.total} />
            </FormItem>
          </FormContent>

          <FormActions>
            <FormActionItem className="left">
              <button className="btnVoltar" onClick={(e) => voltar(e)}>
                Voltar
              </button>
            </FormActionItem>

            <FormActionItem className="right">
              <button type="submit" className="btnSalvar">
                Salvar
              </button>
            </FormActionItem>
          </FormActions>
        </form>
      </Content>
    </Container>
  );
}

export default OrderProductForm;

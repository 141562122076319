import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import Grid from '../../components/Grid';

import { useOrder } from '../../contexts/order';
import MoneyFormat from '../../services/money';

import {
  Container, Content, ListItem, IconDel, Title, IconProduct, IconOrder,
} from './styles';
import { useOrderStatus } from '../../contexts/order_status';
import { useCustomer } from '../../contexts/customer';

function Order() {
  const {
    resetData, data, existsMoreItems, loadMore, loadData, loading, delData, currentPage,
  } = useOrder();

  const { resetData: resetDataOrderStatus } = useOrderStatus();
  const { resetData: resetDataCustomer } = useCustomer();

  const history = useHistory();

  useEffect(() => {
    loadData();
    resetDataCustomer();
    resetDataOrderStatus();

    return () => {
      resetData();
    };
  }, []);

  const goTo = (id) => {
    history.push(`${currentPage}/${id}`);
  };

  const goOrderProduct = (id) => {
    history.push(`${currentPage}/${id}/product`);
  };

  return (
    <Container>
      <Content>
        <Title>
          <IconOrder />
          <span>Pedido</span>
        </Title>

        <Grid
					// newData={() => goTo(0)}
          existsMoreItems={existsMoreItems}
          moreItems={() => loadMore()}
          data={data}
          columns={[
					  { title: 'Situação', align: 'left' },
					  { title: 'Cliente', align: 'left' },
					  { title: 'E-mail', align: 'left' },
					  { title: 'SubTotal', align: 'center' },
					  { title: 'Frete', align: 'center' },
					  { title: 'Total', align: 'center' },
          ]}
          renderItem={(item, i) => ItemGrid(item, i, delData, goTo, goOrderProduct)}
        />

        {loading && <div className="loadingData">Aguarde carregando...</div>}
      </Content>
    </Container>
  );
}

function ItemGrid(item, i, delData, goTo, goOrderProduct) {
  const calcularSubTotal = (obj) => {
    const count = obj.items.reduce((t, i) => t + i.total, 0);

    return count;
  };

  const calcularTotal = (obj) => calcularSubTotal(obj) + obj.frete;

  return (
    <ListItem key={item._id} i={i}>
      <td onClick={() => goTo(item._id)}>{item._idOrderStatus.title}</td>
      <td onClick={() => goTo(item._id)}>{item._idCustomer.name}</td>
      <td onClick={() => goTo(item._id)}>{item._idCustomer.email}</td>
      <td align="center" onClick={() => goTo(item._id)}>
        {MoneyFormat(calcularSubTotal(item))}
      </td>
      <td align="center" onClick={() => goTo(item._id)}>
        {MoneyFormat(item.frete)}
      </td>
      <td align="center" onClick={() => goTo(item._id)}>
        {MoneyFormat(calcularTotal(item))}
      </td>

      <td align="center">
        <div>
          <IconProduct onClick={() => goOrderProduct(item._id)} />
          <IconDel onClick={() => delData(item._id)} />
        </div>
      </td>
    </ListItem>
  );
}

export default Order;

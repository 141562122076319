import React, { useState } from 'react';

import ReactQuill from 'react-quill';

function HtmlEditor({ onFill, field, vlr }) {
  const [value, setValue] = useState(vlr || '');

  const onChange = (e) => {
    setValue(e);

    onFill({
      target: {
        name: field,
        value: e,
      },
    });
  };

  const modules = {
    toolbar: [
      [{ size: ['small', false, 'large', 'huge'] }],

      [{ align: [] }],

      ['bold', 'italic', 'underline', 'blockquote', 'link'],

      [{ list: 'ordered' }, { list: 'bullet' }],

      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],

      [{ color: [] }, { background: [] }],

      ['image', 'clean'],
    ],
  };

  return (
    <ReactQuill
      theme="snow"
      modules={modules}
      value={value}
      onChange={onChange}
      style={{
			  height: 200,
      }}
    />
  );
}

export default HtmlEditor;

import api from './api';

export const getUF = async () => {
  let items = window.localStorage.getItem('mcp-uf');

  if (!items) {
    const { data } = await api.get(
      'https://servicodados.ibge.gov.br/api/v1/localidades/estados',
    );

    items = data;
    window.localStorage.setItem('mcp-uf', JSON.stringify(items));
  }

  try {
    items = JSON.parse(items);
  } catch (e) {}

  return items.sort((a, b) => {
    if (a.sigla < b.sigla) {
      return -1;
    }
    if (a.sigla > b.sigla) {
      return 1;
    }
    return 0;
  });
};

export const getCidades = async (uf) => {
  let items = window.localStorage.getItem(`mcp-city-${uf}`);

  if (!items) {
    const { data } = await api.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`,
    );

    items = data;
    window.localStorage.setItem(`mcp-city-${uf}`, JSON.stringify(items));
  }

  try {
    items = JSON.parse(items);
  } catch (e) {}

  return items.sort((a, b) => {
    if (a.nome < b.nome) {
      return -1;
    }
    if (a.nome > b.nome) {
      return 1;
    }
    return 0;
  });
};

import React, { useState, useEffect } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { useBanner } from '../../contexts/banner';

import {
  Container,
  Content,
  FormItem,
  FormContent,
  FormActions,
  FormActionItem,
  Title,
  IconBanner,
} from './styles';

function BannerForm() {
  const {
    currentData,
    currentPage,
    loadCurrentData,
    resetData,
    loading,
    newData,
    editData,
  } = useBanner();

  const params = useParams();
  const history = useHistory();

  const [form, setForm] = useState({
    position: 0,
    title: '',
    active: false,
    link: '',
  });

  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    resetData();
    loadCurrentData(params.id);
  }, [params.id]);

  useEffect(() => {
    if (currentData) {
      setForm(currentData);
      setPreviewImage(currentData.url);
    } else {
      setForm({
        position: 0,
        title: '',
        active: false,
        link: '',
      });
      setImage(null);
      setPreviewImage(null);
    }
  }, [currentData]);

  const voltar = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const handleChangeRadio = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value === 'true',
    });
  };

  const handleChange = (e) => {
    const vlr = ['number', 'tel'].includes(e.target.type)
      ? parseInt(e.target.value)
      : e.target.value;

    setForm({
      ...form,
      [e.target.name]: vlr,
    });
  };

  const onFileChange = (e) => {
    setImage(e.target.files[0]);
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleSalvar = async (e) => {
    e.preventDefault();

    if (!params.id || params.id === '0') {
      await newData(form, image);
    } else {
      await editData(params.id, form, image);
    }

    history.push(currentPage);
  };

  if (loading) {
    return <div className="loadingData">Aguarde carregando...</div>;
  }

  return (
    <Container>
      <Content>
        <form onSubmit={handleSalvar}>
          <Title>
            <IconBanner />
            <span>Banner</span>
          </Title>

          <FormContent>
            <FormItem className="file">
              <span>
                Imagem
                {' '}
                <sup>*</sup>
              </span>

              {previewImage && (
              <img
                src={previewImage}
                alt="Preview da imagem"
                title="Preview da imagem"
              />
              )}

              <label>
                <input
                  type="file"
                  name="file"
                  onChange={(e) => onFileChange(e)}
                />
                Clique aqui e selecione um arquivo
                {' '}
                <br />
                <span>Dimensões: largura de 2050px e altura de 780px</span>
              </label>
            </FormItem>

            <FormItem>
              <span>Ativo</span>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value
                    name="active"
                    checked={form.active === true}
                    onChange={(e) => handleChangeRadio(e)}
                  />
                  Sim
                </label>
                <label>
                  <input
                    type="radio"
                    value={false}
                    name="active"
                    checked={form.active === false}
                    onChange={(e) => handleChangeRadio(e)}
                  />
                  Não
                </label>
              </div>
            </FormItem>

            <FormItem>
              <span>Ordem</span>
              <input
                type="tel"
                value={form.position}
                name="position"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>
                Titulo
                {' '}
                <sup>*</sup>
              </span>
              <input
                value={form.title}
                name="title"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>Link</span>
              <input
                value={form.link}
                name="link"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>
          </FormContent>

          <FormActions>
            <FormActionItem className="left">
              <button className="btnVoltar" onClick={(e) => voltar(e)}>
                Voltar
              </button>
            </FormActionItem>

            <FormActionItem className="right">
              <button type="submit" className="btnSalvar">
                Salvar
              </button>
            </FormActionItem>
          </FormActions>
        </form>
      </Content>
    </Container>
  );
}

export default BannerForm;

import React, { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useHistory, useParams } from 'react-router-dom';
import Grid from '../../components/Grid';

import { useOrderProduct } from '../../contexts/order_product';
import { useProduct } from '../../contexts/product';

import {
  Container, Content, ListItem, IconDel, Title, IconBack, IconProduct,
} from './styles';
import MoneyFormat from '../../services/money';

function OrderProduct() {
  const {
    resetData, data, existsMoreItems, loadMore, loading, delData, loadData, setOrderId, orderId,
  } = useOrderProduct();

  const { resetData: resetDataProduct } = useProduct();

  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    setOrderId(params.order_id);
  }, [params.order_id]);

  useEffect(() => {
    resetData();
    loadData();
    resetDataProduct();

    return () => {
      resetData();
    };
  }, [orderId]);

  const goBack = () => {
    history.push('/order');
  };

  const goTo = (id) => {
    history.push(`/order/${orderId}/product/${id}`);
  };

  return (
    <Container>
      <Content>
        <Title>
          <IconBack onClick={() => goBack()} />
          <IconProduct />
          <span>Items do pedido</span>
        </Title>

        <Grid
          existsMoreItems={existsMoreItems}
          moreItems={() => loadMore()}
          data={data}
          columns={[
					  { title: 'Imagem', align: 'center' },
					  { title: 'Titulo', align: 'left' },
					  { title: 'Produto apenas virtual', align: 'center' },
					  { title: 'Adicionar ao carrinho', align: 'center' },
					  { title: 'Valor (unit)', align: 'center' },
					  { title: 'Quantidade', align: 'center' },
					  { title: 'Total', align: 'center' },
          ]}
          renderItem={(item, i) => ItemGrid(item, i, delData, goTo)}
        />

        {loading && <div className="loadingData">Aguarde carregando...</div>}
      </Content>
    </Container>
  );
}

function ItemGrid(item, i, delData, goTo) {
  return (
    <ListItem key={item._id} i={i}>
      <td align="center" onClick={() => goTo(item._id)}>
        <LazyLoadImage alt={item._idProduct.title} effect="blur" src={item._idProduct.url} />
      </td>

      <td onClick={() => goTo(item._id)}>
        <label>{item._idProduct.title}</label>
      </td>

      <td align="center" onClick={() => goTo(item._id)}>
        <label>{item.is_virtual ? 'Sim' : 'Não'}</label>
      </td>

      <td align="center" onClick={() => goTo(item._id)}>
        <label>{item.disable_shopping_cart ? 'Sim' : 'Não'}</label>
      </td>

      <td align="center" onClick={() => goTo(item._id)}>
        <label>{MoneyFormat(item.unit)}</label>
      </td>

      <td align="center" onClick={() => goTo(item._id)}>
        <label>{item.qtd}</label>
      </td>

      <td align="center" onClick={() => goTo(item._id)}>
        <label>{MoneyFormat(item.total)}</label>
      </td>

      <td align="center">
        <IconDel onClick={() => delData(item._id)} />
      </td>
    </ListItem>
  );
}

export default OrderProduct;

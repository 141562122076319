import React, { useState, useEffect } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import HtmlEditor from '../../components/HtmlEditor';
import { useTestimony } from '../../contexts/testimony';

import {
  Container,
  Content,
  FormItem,
  FormContent,
  FormActions,
  FormActionItem,
  Title,
  IconTestimony,
} from './styles';

function TestimonyForm() {
  const {
    currentData,
    currentPage,
    loadCurrentData,
    resetData,
    loading,
    newData,
    editData,
  } = useTestimony();

  const params = useParams();
  const history = useHistory();

  const [form, setForm] = useState({
    name: '',
    message: '',
  });

  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    resetData();
    loadCurrentData(params.id);
  }, [params.id]);

  useEffect(() => {
    if (currentData) {
      setForm(currentData);
      setPreviewImage(currentData.url);
    } else {
      setForm({
        name: '',
        message: '',
      });
      setImage(null);
      setPreviewImage(null);
    }
  }, [currentData]);

  const voltar = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const handleChange = (e) => {
    const vlr = ['number', 'tel'].includes(e.target.type)
      ? parseInt(e.target.value)
      : e.target.value;

    setForm({
      ...form,
      [e.target.name]: vlr,
    });
  };

  const onFileChange = (e) => {
    setImage(e.target.files[0]);
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleSalvar = async (e) => {
    e.preventDefault();

    if (!params.id || params.id === '0') {
      await newData(form, image);
    } else {
      await editData(params.id, form, image);
    }

    history.push(currentPage);
  };

  if (loading) {
    return <div className="loadingData">Aguarde carregando...</div>;
  }

  return (
    <Container>
      <Content>
        <form onSubmit={handleSalvar}>
          <Title>
            <IconTestimony />
            <span>Testemunho</span>
          </Title>

          <FormContent>
            <FormItem className="file">
              <span>
                Imagem
                {' '}
                <sup>*</sup>
              </span>

              {previewImage && (
              <img
                src={previewImage}
                alt="Preview da imagem"
                title="Preview da imagem"
              />
              )}

              <label>
                <input
                  type="file"
                  name="file"
                  onChange={(e) => onFileChange(e)}
                />
                Clique aqui e selecione um arquivo
                {' '}
                <br />
                <span>Dimensões: largura de 300px e altura de 300px</span>
              </label>
            </FormItem>

            <FormItem>
              <span>
                Nome
                {' '}
                <sup>*</sup>
              </span>
              <input
                value={form.name}
                name="name"
                onChange={(e) => handleChange(e)}
              />
            </FormItem>

            <FormItem>
              <span>
                Mensagem
                {' '}
                <sup>*</sup>
              </span>
              <HtmlEditor
                onFill={handleChange}
                vlr={form.message}
                field="message"
              />
            </FormItem>
          </FormContent>

          <FormActions>
            <FormActionItem className="left">
              <button className="btnVoltar" onClick={(e) => voltar(e)}>
                Voltar
              </button>
            </FormActionItem>

            <FormActionItem className="right">
              <button type="submit" className="btnSalvar">
                Salvar
              </button>
            </FormActionItem>
          </FormActions>
        </form>
      </Content>
    </Container>
  );
}

export default TestimonyForm;

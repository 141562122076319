import React, { useState, useRef, useEffect } from 'react';
import { useLogin } from '../../contexts/login';

import logo from '../../assets/logotipo.png';
import {
  Container, Content, Form, Logotipo, BoxHelp, BtnSign,
} from './styles';

function Login() {
  const [form, setForm] = useState({
    name: '',
    email: '',
    password: '',
  });

  const textInput = useRef(null);

  useEffect(() => {
    textInput.current.focus();
  }, []);

  const { login } = useLogin();

  const handleSignIn = (e) => {
    e.preventDefault();

    const { email, password } = form;
    login(email, password);
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Container>
      <Content>
        <Form onSubmit={handleSignIn}>
          <Logotipo src={logo} alt="Mulher com Palavra" />
          <div>
            <span>E-mail</span>
            <input
              ref={textInput}
              value={form.email}
              name="email"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div>
            <span>Senha</span>
            <input
              value={form.password}
              name="password"
              onChange={(e) => handleChange(e)}
              type="password"
            />
          </div>

          <BoxHelp>
            <BtnSign type="submit">Validar</BtnSign>
          </BoxHelp>
        </Form>
      </Content>
    </Container>
  );
}

export default Login;

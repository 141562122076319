import styled, { css } from 'styled-components';
import { MdAdd, MdGetApp } from 'react-icons/md';

export const Container = styled.div`
	overflow-y: auto;
	height: ${(props) => (props.existsMoreItems ? 'calc(100% - 102px)' : 'calc(100% - 75px)')};

	table {
		margin: 5px 0;
		width: 99%;
	}

	thead th {
		background: var(--btnImage);
		padding: 5px;
	}

	thead label {
		text-transform: capitalize;
		color: var(--white);
	}

	tbody tr td {
		padding: 5px;
	}

	tbody tr td {
		&.description {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 300px;
		}
	}
`;

export const BtnMore = styled.button`
	padding: 10px;
	font-weight: bold;
	border: 0;
	cursor: pointer;
	width: 100%;
	background: var(--btnImage);
	color: var(--white);
	transition: all 0.2s;

	&:hover {
		background: var(--btnImage-dark);
		color: var(--white);
	}
`;

const cssIcon = css`
	width: 32px;
	height: 32px;
	color: var(--primary);
	border: 1px solid var(--btnHelp);
	padding: 3px;
	border-radius: 50%;
	cursor: pointer;
	transition: all 0.2s;

	&:hover {
		padding: 4px;
		color: var(--white);
		background: var(--gray);
	}
`;

export const IconNew = styled(MdAdd)`
	${cssIcon}
`;

export const IconDownload = styled(MdGetApp)`
	${cssIcon};
	margin-left: 5px;
`;
